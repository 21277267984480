import React from 'react'
import { ServicesContext } from "../ServicesProvider";

const defaultValues = {}

export const EventsContext = React.createContext(defaultValues)

export const EventsProvider = ({ children }) => {
    const { eventsService } = React.useContext(ServicesContext)

    const getEvents = () => eventsService.getEvents()
    const captureSnapshot = (id, arb) => eventsService.captureSnapshot(id, arb)
    const getAlternativeOdds = (id, arb) => eventsService.getAlternativeOdds(id, arb)
    const sendReport = (report) => eventsService.sendReport(report)
    const getUpcomingEvents = () => eventsService.getUpcomingEvents()
    const getReports = () => eventsService.getReports()

    return (
        <EventsContext.Provider value={{
            getEvents,
            captureSnapshot,
            getAlternativeOdds,
            sendReport,
            getUpcomingEvents,
            getReports
        }}>
            {children}
        </EventsContext.Provider>
    )
}
