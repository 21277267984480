import React, { createContext, useCallback, useContext, useMemo } from "react";
import useSWR from "swr";

import { useAuth, useUser } from "../hooks";

import { checkToken } from "../repositories/utils";

export const UsersStoreContext = createContext();
export const useUsers = () => useContext(UsersStoreContext);
export function UsersProvider({ children }) {
  const { getUserInfo, getUsers } = useAuth();
  const { bookieAction } = useUser();

  const getUserInfoWrapped = useCallback(async () => {
    if (checkToken()) {
      return await getUserInfo();
    }
  }, [localStorage.jwt]);

  const { data: userInfo, mutate } = useSWR('user-info', getUserInfoWrapped);

  const users = useMemo(() => getUsers(userInfo), [getUsers, userInfo]);

  const enableBookie = useCallback(
    async (email, name, enabled) => {
      await bookieAction(email, name, enabled ? "disable" : "enable");
      mutate(); // refetches user info data
    },
    [mutate, bookieAction],
  );

  const value = useMemo(() => ({ enableBookie, userInfo, users, mutate }), [enableBookie, users, userInfo, mutate]);

  return <UsersStoreContext.Provider value={value}>{children}</UsersStoreContext.Provider>;
}