import React, { useCallback, useMemo, useState } from "react";

import { ReportModal } from "../../components/ReportModal/ReportModal";

export const ReportContext = React.createContext(undefined);

export const ReportProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [onSubmitted, setOnSubmitted] = useState(undefined);
  const [reportArb, setReportArb] = useState(undefined);

  const closeReportModal = useCallback(() => {
    setShowModal(false);
    setReportArb(undefined);
    setOnSubmitted(undefined);
  }, []);

  const openReportModal = useCallback((arb, onSubmittedCallback) => {
    if (arb) setReportArb(arb);
    // we need to use a function to save a function in setState
    setOnSubmitted(() => onSubmittedCallback);
    setShowModal(true);
  }, []);

  const providerValue = useMemo(() => ({ closeReportModal, openReportModal, reportArb }), [reportArb]);

  return (
    <ReportContext.Provider value={providerValue}>
      <ReportModal close={closeReportModal} onSubmitted={onSubmitted} show={showModal} />
      {children}
    </ReportContext.Provider>
  );
};
