import { useMemo, useRef } from "react";

// Like useMemo but the calculateValue function receives the previous value of the memo
export function useMemoPrev(calculateValue, dependencies) {
  const valueRef = useRef(undefined);
  
  const value = useMemo(() => {
    valueRef.current = calculateValue(valueRef.current)
    return valueRef.current;
  }, dependencies);

  return value;
}
