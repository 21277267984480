import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { SettingsStore } from "../../stores/SettingsStore/SettingsStore";

const defaultValues = {};

export const SettingsContext = React.createContext(defaultValues);

export const SettingsProvider = observer(({ children, type }) => {
  // Create a single settings store for each Provider
  const [settingsStore] = useState(() => new SettingsStore(type))

  return (
    <SettingsContext.Provider value={{ settingsStore }}>
      {children}
    </SettingsContext.Provider>
  );
});
