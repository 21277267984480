// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your translation files
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
};

i18n
  .use(LanguageDetector) // add this line
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: "en", // set your default language here
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
