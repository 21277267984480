import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import '@fortawesome/fontawesome-free/js/all.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App>
        <Helmet>
            <meta property="og:title" content="BetSmarter"/>
            <meta property="og:description" content="BetSmarter - Earn. Money. Safely."/>
            <meta property="og:image" content="/apple-touch-icon.png"/>
            <meta property="og:url" content="https://betsmarter.app"/>
            
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="betsmarter.app"/>
            <meta property="twitter:url" content="https://betsmarter.app"/>
            <meta name="twitter:title" content="BetSmarter"/>
            <meta name="twitter:description" content="BetSmarter - Earn. Money. Safely."/>
            <meta name="twitter:image" content="https://betsmarter.app/apple-touch-icon.png"/>
        </Helmet>
      </App>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
