import { useContext } from "react";

import { SettingsContext } from "../contexts/SettingsProvider";

export const useSettings = () => {
  const { settingsStore } = useContext(SettingsContext);

  // Load store content once the hook is called. The store only loads once internally
  settingsStore.load();

  return settingsStore;
};
