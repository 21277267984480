import React from "react";
import { createAuthRepository, createEventsRepository, createUserRepository } from "../../repositories";
import { createAuthService } from "../../services/AuthService/Auth.service";
import { createUserService } from "../../services/UserService/User.service";
import { createEventsService } from "../../services/EventsService/Events.service";

export const ServicesContext = React.createContext(undefined);

export const ServicesProvider = ({ children }) => {
  const authRepository = createAuthRepository();
  const authService = createAuthService(authRepository);

  const userRepository = createUserRepository();
  const userService = createUserService(userRepository);

  const eventsRepository = createEventsRepository();
  const eventsService = createEventsService(eventsRepository);

  return (
    <ServicesContext.Provider
      value={{
        authService,
        userService,
        eventsService,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
